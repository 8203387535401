<template>
  <v-row justify-center>
    <v-dialog max-width="575" v-model="dialogVisible" persistent>
      <div v-if="state === vueModalState.SELECTING_FILES">
        <v-card>
          <v-btn right absolute text icon color="grey" @click="onCancelUploadClick()">
            <v-icon>fa fa-times</v-icon>
          </v-btn>
          <v-card-title class="headline pb-0"> 3D model upload </v-card-title>
          <v-card-subtitle class="mt-0 border-bottom"> Select files to upload </v-card-subtitle>
          <v-card-text class="border-bottom" style="font-size: 1rem">
            <v-form class="mt-4" v-model="isFilesInputValid">
              <v-file-input
                outlined
                show-size
                counter
                multiple
                label="Select files..."
                :accept="fileTypesAccepted"
                @change="onModelFileInputChange"
                :loading="commencedUpload"
                :disabled="commencedUpload"
                :rules="modelUploadRules"
              >
              </v-file-input>
              <v-card-subtitle v-if="showSldzHint" class="mt-0 pt-0 border-bottom">
                We noticed you're uploading many .sldasm or .sldprt files. If you would like to import an entire SolidWorks project (.sldasm
                + .sldprt), please check our
                <a
                  href="https://community.jig.space/t/importing-3d-models-and-cad-files/13#solidworks"
                  target="_blank"
                  rel="noopener noreferrer"
                  >SolidWorks guidelines</a
                >.
              </v-card-subtitle>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="darkGrey--text" color="secondary" @click="onCancelUploadClick()">Cancel</v-btn>
            <v-btn color="primary" @click="onBeginClick()" :disabled="!isFilesInputValid">Begin upload</v-btn>
          </v-card-actions>
        </v-card>
      </div>
      <div v-else-if="state === vueModalState.UPLOADING_PROCESSING_MANY">
        <v-card>
          <v-card-title class="headline pb-0"> Uploading {{ XOfYMessage }} </v-card-title>
          <v-card-subtitle class="mt-0 border-bottom"> Please wait while your files upload ... </v-card-subtitle>
          <v-card-text class="border-bottom overflow-y-auto" style="font-size: 1rem; max-height: 300px">
            <v-list-item dense v-for="(item, i) in selectedFilesWithProgress" :key="i">
              <v-list-item-content>
                <v-list-item-title v-text="item.file.name + ' ' + getUploadingStateDisplay(item.uploadingState)"></v-list-item-title>
                <v-list-item-subtitle>
                  <v-progress-linear
                    v-if="item.uploadingState !== uploadingStates.ERROR"
                    :value="getUploadProgress(item.file)"
                    :indeterminate="item.uploadingState === uploadingStates.PROCESSING"
                  ></v-progress-linear>
                  <div class="overflow-y-auto" v-if="item.uploadingState === uploadingStates.ERROR">{{ item.errorMsg }}</div>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="darkGrey--text" color="secondary" @click="onCancelUploadClick()">Cancel</v-btn>
            <v-btn color="primary" @click="onTogglePauseUploadClick()" :disabled="!commencedUpload">{{ pauseLabel }}</v-btn>
          </v-card-actions>
        </v-card>
      </div>
      <div v-else-if="state === vueModalState.RESULTS">
        <v-card>
          <v-card-title class="headline pb-0"> Upload complete </v-card-title>
          <v-card-subtitle class="mt-0 border-bottom"> </v-card-subtitle>
          <v-card-text class="border-bottom" style="font-size: 1rem">
            <v-row justify="center" align="center">
              <div class="text-xs-center mb-3">
                <br />
                <div>Uploaded the following files:</div>
              </div>
            </v-row>
            <v-list-item dense v-for="(item, i) in selectedFilesWithProgress" :key="i">
              <v-list-item-content>
                <v-list-item-title v-text="item.file.name"></v-list-item-title>
                <v-list-item-subtitle>
                  <div v-if="item.uploadingState === uploadingStates.ERROR">
                    <div class="overflow-y-auto" v-if="item.uploadingState === uploadingStates.ERROR" style="color: red">
                      {{ item.errorMsg }}
                    </div>
                  </div>
                  <div v-else>Succeeded</div>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="closeDialog()">Close</v-btn>
          </v-card-actions>
        </v-card>
      </div>
    </v-dialog>
  </v-row>
</template>

<script>
import { AppConst } from '@/constants'
import helpers from '@/modules/library/helpers'
import { VersionConstants } from '@/store/modules/version/constants'
import { ValidationRules } from '@/utils/input-validation'
import { TenantHelpers } from '@/utils/tenant-helpers'
import { segmentEventTracking } from '@/utils/tracking'
import { mapActions, mapGetters, mapState } from 'vuex'

const modalState = {
  SELECTING_FILES: 1,
  UPLOADING_PROCESSING_MANY: 4,
  RESULTS: 5,
}

const uploadingState = {
  WAITING: 1,
  UPLOADING: 2,
  UPLOAD_PAUSED: 3,
  PROCESSING: 4,
  DONE: 5,
  ERROR: 6,
}

Object.freeze(modalState)

export default {
  name: 'UploadModelDialog',
  data() {
    return {
      dialogVisible: false,
      commencedUpload: false, // Denotes if we selected a file and pressed the begin upload button
      isFilesInputValid: false,
      currentUploadingFileIndex: 0,
      state: modalState.SELECTING_FILES,
      vueModalState: modalState,
      multiUploadJobIndex: -1,
      pauseLabel: 'Pause Upload',
      selectedFilesWithProgress: [
        {
          file: {}, // File type
          uploadProgress: 0,
          uploadingState: uploadingState.WAITING,
          errorMsg: '',
        },
      ],
      uploadingStatesDisplay: {
        1: '(Waiting)',
        2: '(Uploading)',
        3: '(Paused)',
        4: '(Processing)',
        5: '(Done)',
        6: '(Error)',
      },
      cancelAllClicked: false,
      anyUploadSuccessful: false,
      showSldzHint: false,
      latestDesktopVersionName: '',
    }
  },
  computed: {
    ...mapGetters('app', ['allowedModelFileFormats']),
    ...mapState('app', ['myTenants']),
    ...mapState('library', ['jobs']),
    ...mapState('version', ['latestDesktopVersion']),
    ...mapState('utils', ['apiError']),
    fileTypesAccepted() {
      return this.allowedModelFileFormats.join(',')
    },
    uploadProgress() {
      return Math.round(this.trackedFileUpload.uploadProgressPercentage)
    },
    trackedFileUpload() {
      let trackedUpload = {
        fileHandle: null,
        uploadInProgress: false,
        result: '',
        bytesUploaded: 0,
        bytesTotal: 0,
        uploadProgressPercentage: 0,
        errorMsg: '',
        jigStaffUser: false,
      }
      if (this.currentUploadingFile !== undefined) {
        const result = this.getMultiUploadJobByFile(this.currentUploadingFile)
        if (result.multiUploadJob !== null) {
          trackedUpload = result.multiUploadJob.files[result.uploadIndex]
        }
      }
      return trackedUpload
    },
    trackedFileUploadError() {
      return this.trackedFileUpload.errorMsg
    },
    canCancelUpload() {
      // update this
      return this.uploadProgress < 100
    },
    jobId() {
      let jobId = 0
      if (this.currentUploadingFile !== undefined) {
        const result = this.getMultiUploadJobByFile(this.currentUploadingFile)
        if (result.multiUploadJob !== null) {
          jobId = result.multiUploadJob.job.id
        }
      }
      return jobId
    },
    uploadFinished() {
      let uploadFinished = false
      if (this.currentUploadingFile !== undefined) {
        const result = this.getMultiUploadJobByFile(this.currentUploadingFile)
        if (result.multiUploadJob !== null) {
          const trackedUpload = result.multiUploadJob.files[result.uploadIndex]
          uploadFinished = trackedUpload.finished
        }
      }
      return uploadFinished
    },
    currentUploadingFile() {
      if (this.selectedFilesWithProgress === undefined || this.selectedFilesWithProgress[this.currentUploadingFileIndex] === undefined) {
        return undefined
      }
      return this.selectedFilesWithProgress[this.currentUploadingFileIndex].file
    },
    modelUploadRules() {
      return ValidationRules.ModelUploadFiles
    },
    XOfYMessage() {
      if (this.selectedFilesWithProgress !== undefined && this.selectedFilesWithProgress.length > 1) {
        return `${this.currentUploadingFileIndex + 1} of ${this.selectedFilesWithProgress.length}`
      }
      return ''
    },
    uploadingStates() {
      return uploadingState
    },
    trackingEvent() {
      return {
        action: 'UploadModel',
      }
    },
  },
  watch: {
    // We watch the jobId for changes as this will tell us when the initial file POST has completed
    // successfully and we could start polling the job for a finished/failed state. However we wait until the file upload
    // is complete. We just remember the multi upload job index.
    // TODO: Work out a better way to sync all this together.
    jobId(oldId, newId) {
      if (this.cancelAllClicked) {
        return
      }
      for (const cj of this.jobs) {
        if (cj.job.id === this.jobId) {
          this.multiUploadJobIndex = cj.index
          return
        }
      }
    },
    uploadFinished(oldValue, newValue) {
      if (this.cancelAllClicked) {
        segmentEventTracking('TenantModelUploadCanceled', {
          ...this.trackingEvent,
          fileName: this.selectedFilesWithProgress[this.currentUploadingFileIndex].file.name,
        })
        return
      }
      if (this.uploadFinished) {
        // The file upload has finished. Start polling for job state to be done.
        // Because the job is created before the upload is finished the multiUploadJobIndex should already be set.
        if (this.multiUploadJobIndex !== -1) {
          // Check Errors
          const errMsg = this.getErrorMsgFromFile(this.currentUploadingFile)
          if (errMsg) {
            this.selectedFilesWithProgress[this.currentUploadingFileIndex].errorMsg = errMsg
            this.selectedFilesWithProgress[this.currentUploadingFileIndex].uploadingState = uploadingState.ERROR

            if (this.nextExists()) {
              this.startNext()
              return
            }
          }

          this.selectedFilesWithProgress[this.currentUploadingFileIndex].uploadingState = uploadingState.PROCESSING
          this.waitForJobFinishAndShowResultsOrStartNext()
        } else {
          segmentEventTracking('TenantModelUploadCompleted', {
            ...this.trackingEvent,
            fileName: this.selectedFilesWithProgress[this.currentUploadingFileIndex].file.name,
          })
          consoleLog('Upload finished and no job ID exists yet!')
        }
      }
    },
    trackedFileUploadError(oldValue, newValue) {
      if (this.cancelAllClicked) {
        return
      }
      if (this.trackedFileUploadError !== '') {
        this.state = modalState.RESULTS
      }
    },
  },
  async created() {
    // Dev and test (probably staging too) environment usually don't have Version at all once re-deployed.
    // If no version is available all work will fail on this page, hence redirecting to Versions page
    // to encourage user to at least create a 3.11.0.0 desktop version
    if (this.apiError.indexOf(AppConst.Versions.versionErrorName) >= 0) {
      this.$router.push('/versions')
    }

    if (
      !this.latestDesktopVersion[VersionConstants.VersionDefaults.DefaultApplication] &&
      this.apiError.indexOf(AppConst.Versions.versionErrorName) === -1
    ) {
      await this.getLatestVersionUrl(
        `${VersionConstants.VersionDefaults.DefaultApplication}/${VersionConstants.VersionDefaults.DefaultPlatform}`
      )
    }
  },
  mounted() {
    if (this.latestDesktopVersion[VersionConstants.VersionDefaults.DefaultApplication]) {
      this.latestDesktopVersionName = this.latestDesktopVersion[VersionConstants.VersionDefaults.DefaultApplication].Name
    }
  },
  methods: {
    ...mapActions('library', ['multiUploadJobsClearAll', 'uploadFile', 'pollJobUntilFinalResultAsync', 'multiUploadJobArchive']),
    ...mapActions('version', ['getLatestVersionUrl']),
    getUploadingStateDisplay(uploadingState) {
      return this.uploadingStatesDisplay[uploadingState]
    },
    getUploadProgress(file) {
      const result = this.getMultiUploadJobByFile(file)
      if (result.multiUploadJob !== null) {
        return Math.round(result.multiUploadJob.files[result.uploadIndex].uploadProgressPercentage)
      } else {
        return 0
      }
    },
    startUploadOfManyFiles() {
      this.state = modalState.UPLOADING_PROCESSING_MANY
      this.commencedUpload = true
      this.$emit('uploading-started')
      this.selectedFilesWithProgress[this.currentUploadingFileIndex].uploadingState = uploadingState.UPLOADING

      this.uploadFile({
        fileToUpload: this.currentUploadingFile,
        latestDesktopVersionName: this.latestDesktopVersionName,
      })
    },
    async waitForJobFinishAndShowResultsOrStartNext() {
      // This function will keep polling the job until a done state is found.
      segmentEventTracking('TenantModelProcessingStarted', {
        ...this.trackingEvent,
        fileName: this.selectedFilesWithProgress[this.currentUploadingFileIndex].file.name,
      })
      await this.pollJobUntilFinalResultAsync(this.multiUploadJobIndex)
      segmentEventTracking('TenantModelProcessingCompleted', {
        ...this.trackingEvent,
        fileName: this.selectedFilesWithProgress[this.currentUploadingFileIndex].file.name,
      })

      // Check Errors
      const errMsg = this.getErrorMsgFromFile(this.currentUploadingFile)
      if (errMsg) {
        this.selectedFilesWithProgress[this.currentUploadingFileIndex].errorMsg = errMsg
        this.selectedFilesWithProgress[this.currentUploadingFileIndex].uploadingState = uploadingState.ERROR
        // This might be the error from processing so need to track it separately
        segmentEventTracking('TenantModelUploadProcessingErrored', {
          ...this.trackingEvent,
          fileName: this.selectedFilesWithProgress[this.currentUploadingFileIndex].file.name,
        })
      } else {
        this.anyUploadSuccessful = true
        this.selectedFilesWithProgress[this.currentUploadingFileIndex].uploadingState = uploadingState.DONE
        segmentEventTracking('TenantModelUploadCompleted', {
          ...this.trackingEvent,
          fileName: this.selectedFilesWithProgress[this.currentUploadingFileIndex].file.name,
        })
      }
      // After processing finished, CancelAll may have been clicked so check that before continuing
      if (this.cancelAllClicked) {
        segmentEventTracking('TenantModelUploadCanceled', {
          ...this.trackingEvent,
          fileName: this.selectedFilesWithProgress[this.currentUploadingFileIndex].file.name,
        })
        return
      }

      // if there is another file in the selected files list, start uploading that one
      if (this.nextExists()) {
        this.startNext()
        return
      }
      this.state = modalState.RESULTS
    },
    getProcessingStatusForFile(file) {
      const result = this.getMultiUploadJobByFile(file)
      if (result.multiUploadJob !== null) {
        return result.multiUploadJob.job.status.toUpperCase()
      }
      return ''
    },
    // Looks up the multi upload job based on the file path. Designed to allow us to track
    // multiple files being uploaded.
    getMultiUploadJobByFile(file) {
      return helpers.getMultiUploadJobByFilename(this.$store.state.library, file.name)
    },
    getErrorMsgFromFile(file) {
      const multiUploadJobAndIndex = this.getMultiUploadJobByFile(file)
      var msg = ''
      if (multiUploadJobAndIndex.multiUploadJob !== null) {
        const trackedFile = multiUploadJobAndIndex.multiUploadJob.files[multiUploadJobAndIndex.uploadIndex]
        if (trackedFile !== null && trackedFile.errorMsg !== '') {
          msg = `Upload Error: ${trackedFile.errorMsg}` // upload error message
        } else if (multiUploadJobAndIndex.multiUploadJob.job !== null && multiUploadJobAndIndex.multiUploadJob.job.errorMsg !== '') {
          msg = `Processing Error: ${multiUploadJobAndIndex.multiUploadJob.job.errorMsg}` // pipeline or job error message
          if (multiUploadJobAndIndex.multiUploadJob.job.jobMeta && multiUploadJobAndIndex.multiUploadJob.job.jobMeta.pipelineStatus) {
            msg += `\nProcessing Status was: ${multiUploadJobAndIndex.multiUploadJob.job.jobMeta.pipelineStatus}`
          }
        }
      }
      if (msg) {
        consoleLog(`Something went wrong with ${file.name} ${msg}`)
        msg = 'Something went wrong while processing your file.'
      }
      return msg
    },
    nextExists() {
      return this.selectedFilesWithProgress.length - 1 > this.currentUploadingFileIndex
    },
    startNext() {
      this.currentUploadingFileIndex++
      this.commencedUpload = true
      this.$emit('uploading-started')
      this.selectedFilesWithProgress[this.currentUploadingFileIndex].uploadingState = uploadingState.UPLOADING
      this.uploadFile({
        fileToUpload: this.currentUploadingFile,
        latestDesktopVersionName: this.latestDesktopVersionName,
      })
    },
    onModelFileInputChange(files) {
      // files is a File[]
      this.showSldzHint = this.isMoreThanOneSolidWorksSelected(files)
      this.currentUploadingFileIndex = 0
      this.selectedFilesWithProgress = files.map((file) => ({
        file,
        uploadProgress: () => {
          const result = this.getMultiUploadJobByFile(this.file)
          if (result.multiUploadJob !== null) {
            return Math.round(result.multiUploadJob.files[result.uploadIndex])
          } else {
            return 0
          }
        },
        uploadingState: uploadingState.WAITING,
        errorMsg: '',
      }))
    },
    onBeginClick() {
      if (this.selectedFilesWithProgress !== null && this.isFilesInputValid) {
        this.startUploadOfManyFiles()
      }
    },
    onTogglePauseUploadClick() {
      const result = this.getMultiUploadJobByFile(this.currentUploadingFile)
      if (result.multiUploadJob !== null) {
        const trackedFile = result.multiUploadJob.files[result.uploadIndex]
        const tusUpload = trackedFile.tusUpload
        if (tusUpload) {
          if (trackedFile.uploadInProgress) {
            tusUpload.abort()
            trackedFile.uploadInProgress = false
            this.selectedFilesWithProgress[this.currentUploadingFileIndex].uploadingState = uploadingState.UPLOAD_PAUSED
            this.pauseLabel = 'Resume Upload'
          } else {
            trackedFile.uploadInProgress = true
            this.selectedFilesWithProgress[this.currentUploadingFileIndex].uploadingState = uploadingState.UPLOADING
            tusUpload.start()
            this.pauseLabel = 'Pause Upload'
          }
        }
      }
    },
    onCancelUploadClick() {
      if (
        this.selectedFilesWithProgress !== null &&
        this.selectedFilesWithProgress !== undefined &&
        this.selectedFilesWithProgress.length > 0 &&
        this.currentUploadingFile
      ) {
        const result = this.getMultiUploadJobByFile(this.currentUploadingFile)
        if (result.multiUploadJob !== null) {
          const trackedFile = result.multiUploadJob.files[result.uploadIndex]
          const tusUpload = trackedFile.tusUpload
          if (tusUpload) {
            if (trackedFile.uploadInProgress) {
              tusUpload.abort()
              trackedFile.uploadInProgress = false
            }
          }
        }
      }
      this.cancelAllClicked = true
      this.closeDialog()
    },
    isMoreThanOneSolidWorksSelected(files) {
      if (files === undefined || files.length === undefined || files.length <= 1) {
        return false
      }
      let foundSolidWorksFileCount = 0
      for (let index = 0; index < files.length; index++) {
        const file = files[index]
        // Lots of checks to handle if files are uploaded without extension or with a single period
        if (file.name !== undefined) {
          let splitstr = file.name.split('.')
          if (splitstr.length > 1) {
            let ext = splitstr.pop().toLowerCase()
            if (ext == 'sldasm' || ext == 'sldprt') {
              foundSolidWorksFileCount++
            }
          }
        }
      }
      return foundSolidWorksFileCount > 1
    },
    closeDialog() {
      if (this.anyUploadSuccessful) {
        this.$emit('upload-success')
      } else {
        this.$emit('uploading-canceled')
      }
      this.dialogVisible = false
    },
    showDialog() {
      this.multiUploadJobsClearAll()
      this.commencedUpload = false
      this.selectedFilesWithProgress = [{}]
      this.state = modalState.SELECTING_FILES
      this.dialogVisible = true
      this.multiUploadJobIndex = -1
      this.jigStaffUser = false
      this.cancelAllClicked = false
      this.currentUploadingFileIndex = 0
      this.anyUploadSuccessful = false

      for (const tenant of this.myTenants) {
        if (tenant.ID === TenantHelpers.GetJigSpaceStaffTenantID()) {
          this.jigStaffUser = true
        }
      }
    },
  },
}
</script>
